// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  devTools:
    process.env.REACT_APP_REDUX_DEV_TOOLS === "true" ||
    process.env.REACT_APP_REDUX_DEV_TOOLS === true
      ? true
      : false,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export { store };
